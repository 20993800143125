<template>
<div class="person" v-for="item in abogados" :style="{ backgroundImage: `url(${require('@/assets/' + item.style.image)})` }" :key="item.id">
<div class="name">
  <div class="cuadro"></div>
  <p><b>{{item.name}}</b>  </p>
  
</div>

</div>
</template>


<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: "Carrusel",
  props: {
    msg: String,
   
  },
  components: {
   Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
       abogados:[
    
      {id:0,name:'Antonio H. Guillen',style:{
        image:'team/Antonio-G.jpg'
      }},
      {id:1,name:'Antonio J. Guillen',style:{
        image:'team/Antonio.jpg'
      }},
        {id:2,name:'Alba Luz Pacheco',style:{
        image:'team/AlbaLuz.jpg'
      }},
        {id:3,name:'Omar Chavez',style:{
          image:'team/Omar.jpg'
        }},
        {id:4,name:'Luis Altamirano',style:{
          image:'team/LuisAltamirano.jpg'
        }},
        {id:5,name:'Ricxy Martinez',style:{
          image:'team/RicxiMartinez.jpg'
        }},
      {id:6,name:'Jano de Leon',style:{
        image:'team/Jano.jpg'
      }},
      {id:7,name:'Roberto Peña',style:{
        image:'team/SantoRoberto.jpg'
      }},
      {id:8,name:'Vanessa Chavez',style:{
        image:'team/Vanesa.jpg'
      }},
    ]
      // bgimg:'../team/Antonio.jpg'
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";
.cuadro{
  width: 30px;
  height: 5px;
   background-color: #c7893e;
   margin-bottom: 10px;
}
.Carrusel {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

   @include responsive(mobile) {

    
      }
}
  .person {
    width: 200px !important;
    height: 340px;
    // background-image: url("team/Antonio.jpg");
    background-size: 100%;
    background-position: center;
    transition: 0.3s ease-in-out;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    &:hover {
      background-size: 110%;
    }
    .name {
      position: absolute;
      color: white;
      font-size: 20px !important;
      left:10px;
      bottom: 0px;
      width: 180px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: -10px;
    }
  }
.cuadro {
  width: 30px;
  height: 5px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.carousel__pagination-button{
   margin: var(--vc-pgn-margin);
    width: var(--vc-pgn-width);
    height: var(--vc-pgn-height);
    border-radius: var(--vc-pgn-height);
    border: 0;
    cursor: pointer;
    background-color: var(--vc-pgn-bg-color);
    background: #fff;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--carousel-color-primary);
  color:  var(--carousel-color-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
