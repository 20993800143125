<template>
  <div class="Contact">
    <div class="desgradado"></div>
  </div>
  <div class="wrap-grey">
    <div class="container">
      <div class="row conte">
     
        <div class="col-12 formulario col-sm-12 col-lg-6">
          <div class="wrap-form">
            <h1>
              <b> {{ leng == "es" ? "Contacto" : "Contact" }} </b>
            </h1>
            <!-- <form id="contact-form" method="post" action="contact.php"> -->
            <form form id="contact-form" method="post" :action="leng=='es'?'contact.php':'contact_en.php'">
              <div class="messages"></div>
              <div class="mb-3">
                <!-- <label for="exampleInputEmail1" class="form-label">Nombre</label> -->
                <input
                  id="form_name"
                  type="text"
                  class="form-control"
                  name="name"
                  :placeholder="leng == 'es' ? 'Nombre Completo' : 'Full name'"
                  required="required"
                  v-model="form.Nombre"
                />
                <!-- <div id="emailHelp" class="form-text">
                We'll never share your email with anyone else.
              </div> -->
              </div>
          
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="exampleFormControlInput1"
                  :placeholder="leng == 'es'?'Correo Electrónico':'Email'"
                  v-model="form.mail"
                />
              </div>
              <div class="mb-3">
                <input
                  id="numero"
                  type="tel"
                  class="form-control"
                  name="numero"
                  :placeholder="
                    leng == 'es' ? 'Número Celular' : 'Phone Number'
                  "
                  required="numero"
                  v-model="form.celular"
                />
              </div>
                  <div class="mb-3">
               
                <input
                  id="form_pais"
                  type="text"
                  class="form-control"
               name="pais"
                  :placeholder="leng == 'es' ? 'País' : 'Country'"
                  required="required"
                  v-model="form.pais"
                />
              
              </div>
            
              <div class="mb-3">
                <textarea
                  id="form_message"
                  class="form-control"
                  name="message"
                  :placeholder="leng == 'es' ? 'Mensaje' : 'Message'"
                  rows="4"
                  required="required"
                  v-model="form.comentario"
                ></textarea>
              </div>
              <div class="mb-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    name="terms"
                       required="required"
                    data-toggle="modal" data-target="#exampleModal"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    <i style="color: red">*</i>
                    {{
                      leng == "es"
                        ? "Acepto términos y condiciones"
                        : "I accept terms and conditions"
                    }}
                  </label>
                </div>
              </div>
              <button
                style="width: 200px"
                class="btn btn-primary btn-size"
                type="submit"
              >
                {{ leng == "es" ? "ENVIAR" : "SEND" }}
              </button>
            </form>
          </div>
        </div>
           <div class="col-12 img-bg col-sm-12 col-lg-6">
          <div class="wrap-info-contact">
            <h3><b> {{leng =="es"?'CONTÁCTATE CON NOSOTROS':'CONTACT US'}}    </b></h3>
            <b>{{leng =="es"?'Correo electrónico':'Email'}}</b>
            <a    target="_blank"
            data-toggle="tooltip"
            data-placement="top"
            title="Tooltip on top"
            href="mailto:contact@guillen-law.com"> <p>contact@guillen-law.com</p></a>
            <div class="linea"></div>
            <b> {{leng =="es"?'¡Quédate en contacto!':'Stay in touch!'}} </b>
            <div class="redes-socials">
              <a target="_blank" href="https://www.linkedin.com/company/guillen-guillen-abogados/"><i class="fab fa-linkedin-in"></i></a>
                   <a target="_blank" href="https://www.facebook.com/GuillenLaw"><i class="fab fa-facebook"></i></a>
        
          <a target="_blank" href="https://www.instagram.com/guillen_law/"><i class="fab fa-instagram"></i></a>
                <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=50431721915"
            ><i class="fab fa-whatsapp"></i
          ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{leng =="es"?'Terminos y condiciones':'Terms and condition'}} </h5>
        <button type="button"  class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
<p v-if="leng=='es'" class="modal-text">
Este sitio web es propiedad y está operado por Guillen & Guillen Attorneys at Law. Estos Términos establecen los términos y condiciones bajo los cuales puede utilizar nuestro sitio web y los servicios que ofrecemos. Este sitio web ofrece a los visitantes información sobre nuestro bufete de abogados en Honduras, los servicios que ofrece y recopilamos información de las personas que nos contactan a través de él. Al acceder o utilizar el sitio web de nuestro servicio, usted acepta que ha leído, comprendido y aceptado estar sujeto a estos Términos: <br> <br>

1. Para utilizar nuestro sitio web y / o recibir nuestros servicios, debe tener al menos 18 años o la mayoría de edad legal en su jurisdicción o si es menor de edad, necesita la aprobación y representación de sus representantes legales, y Poseer la autoridad legal, el derecho y la libertad para entrar en estos Términos como un acuerdo vinculante. No se le permite utilizar este sitio web y / o recibir servicios si hacerlo está prohibido en su país o bajo cualquier ley o reglamento aplicable a su caso. <br> <br>
2. Podemos, sin previo aviso, cambiar los servicios; dejar de proporcionar los servicios o cualquier característica de los servicios que ofrecemos; o crear límites para los servicios. Podemos suspender de forma permanente o temporal el acceso a los servicios sin previo aviso ni responsabilidad por cualquier motivo, o sin ningún motivo. <br> <br>
3. El Servicio y todos los materiales incluidos o transferidos, incluidos, entre otros, software, imágenes, texto, gráficos, logotipos, patentes, marcas comerciales, marcas de servicio, derechos de autor, fotografías, audio, videos, música y todos los derechos de propiedad intelectual relacionados con son propiedad exclusiva de Guillen & Guillen Attorneys at Law. Salvo que se indique explícitamente en este documento, nada en estos Términos se considerará que crea una licencia en o bajo dichos Derechos de Propiedad Intelectual, y usted acepta no vender, licenciar, alquilar, modificar, distribuir, copiar, reproducir, transmitir, públicamente mostrar, ejecutar públicamente, publicar, adaptar, editar o crear trabajos derivados de los mismos. <br> <br>
4. Usted acepta indemnizar y eximir a Guillen & Guillen Attorneys at Law de cualquier reclamo, pérdida, responsabilidad, reclamo o gasto (incluidos los honorarios de los abogados) que terceros puedan incurrir en su contra como consecuencia de, o derivado de, o en relación con su uso del sitio web o cualquiera de los servicios ofrecidos en el sitio web. <br> <br>
5. En la máxima medida permitida por la ley aplicable, en ningún caso el [Guillen & Guillen Attorneys at Law] propietario del sitio web será responsable de daños indirectos, punitivos, incidentales, especiales, consecuentes o ejemplares, incluidos, entre otros, a, daños por lucro cesante, fondo de comercio, uso, datos. u otras pérdidas intangibles, que surjan o estén relacionadas con el uso o la imposibilidad de usar el servicio. En la medida máxima permitida por la ley aplicable, Guillen & Guillen Attorneys at Law [el propietario del sitio web] no asume ninguna responsabilidad por (i) errores, errores o inexactitudes del contenido; (ii) lesiones personales o daños a la propiedad, de cualquier naturaleza, como resultado de su acceso o uso de nuestro servicio; y (iii) cualquier acceso o uso no autorizado de nuestros servidores seguros y / o toda la información personal almacenada en ellos.<br> <br>
6. Nos reservamos el derecho de modificar estos términos de vez en cuando a nuestro exclusivo criterio. Por lo tanto, debe revisar estas páginas periódicamente. Su uso continuado del sitio web o nuestro servicio después de dicho cambio constituye su aceptación de los nuevos Términos. Si no acepta alguno de estos términos o cualquier versión futura de los mismos, no utilice ni acceda (o continúe accediendo) al sitio web o al servicio.<br> <br>
7. Acepta recibir nuestros mensajes y materiales promocionales de vez en cuando, por correo postal, correo electrónico o cualquier otro formulario de contacto que nos proporcione (incluido su número de teléfono para llamadas o mensajes de texto). Si no desea recibir dichos materiales promocionales o avisos, simplemente avísenos en cualquier momento.<br> <br>
8. Estos Términos, los derechos y recursos aquí provistos, y todas y cada una de las reclamaciones y disputas relacionadas con este y / o los servicios, se regirán, interpretarán y aplicarán en todos los aspectos única y exclusivamente de acuerdo con las leyes sustantivas. 'autoridades internas de Honduras, sin respeto a sus principios de conflicto de leyes. Todos los reclamos y disputas serán presentados y usted acepta que serán decididos exclusivamente por un tribunal de jurisdicción competente ubicado en San Pedro Sula, Departamento de Tribunales, Honduras.
</p>


     <p  v-if="leng=='en'" class="modal-text">
This website is owned and operated by Guillen & Guillen Attorneys at Law. These Terms establish the terms and conditions under which you can use our website and services offered by us. This website offers visitors information about our law firm in Honduras, the services it offers, and we collect information from the people who contact us through it. By accessing or using the website of our service, you agree that you have read, understood, and agreed to be bound by these Terms: <br><br>
1. To use our website and / or receive our services, you must be at least 18 years old, or the legal age of majority in your jurisdiction or if you are a minor, you need the approval and representation of your legal representatives, and possess the legal authority, right and freedom to enter these Terms as a binding agreement. You are not allowed to use this website and / or receive services if doing so is prohibited in your country or under any law or regulation applicable to your case. <br><br>
2. We may, without prior notice, change the services; stop providing the services or any features of the services we offer; or create limits for services. We may permanently or temporarily suspend access to services without notice or liability for any reason, or for no reason at all. <br><br>
3. The Service and all materials included or transferred, including, without limitation, software, images, text, graphics, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and all Intellectual Property Rights related to them are the exclusive property of Guillen & Guillen Attorneys at Law. Except as explicitly stated in this document, nothing in these Terms shall be deemed to create a license in or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit, or create derivative works thereof. <br><br>
4. You agree to indemnify and hold Guillen & Guillen Attorneys at Law harmless from any claim, loss, liability, claim or expense (including attorneys' fees) that third parties may incur against you as a consequence of, or derived from, or in relation to your use of the website or any of the services offered on the website
5. To the maximum extent permitted by applicable law, in no case shall the [Guillen & Guillen Attorneys at Law] owner of the website be liable for indirect, punitive, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data. or other intangible losses, arising from or related to the use or inability to use the service. To the maximum extent permitted by applicable law, Guillen & Guillen Attorneys at Law [the owner of the website] does not assume any responsibility for (i) errors, errors, or inaccuracies of content; (ii) personal injury or property damage, of whatever nature, as a result of your access or use of our service; and (iii) any unauthorized access or use of our secure servers and / or all personal information stored therein. <br><br>
6. We reserve the right to modify these terms from time to time at our sole discretion. Therefore, you should review these pages periodically. Your continued use of the website or our service after such change constitutes your acceptance of the new Terms. If you do not accept any of these terms or any future version of the Terms, do not use or access (or continue to access) the website or the service. <br><br>
7. You agree to receive our messages and promotional materials from time to time, by post, email, or any other contact form that you provide us (including your phone number for calls or text messages). If you do not wish to receive such promotional materials or notices, simply let us know at any time. <br>
8. These Terms, the rights and remedies provided here, and each and every one of the claims and disputes related to this and / or the services, will be governed, interpreted and applied in all respects solely and exclusively in accordance with the substantive laws’ internal authorities of Honduras, without respect for its principles of conflict of laws. All claims and disputes will be presented, and you agree that they will be decided exclusively by a court of competent jurisdiction located in San Pedro Sula, Department of Courts, Honduras. 


     </p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"> {{leng =="es"?'Cerrar':'Close'}} </button>
        <!-- <button type="button" class="btn btn-primary">{{leng =="es"?'Aceptar':'Accept'}} </button> -->
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
// @ is an alias to /src
import store from "@/store/index.js";
import Swal from "sweetalert2";
import axios from "axios";
export default {
  name: "Contact",
  components: {},
  data() {
    this.$i18n.locale = store.state.activo;
    return {
      leng: store.state.activo,
      form: {
        Nombre: null,
        mail: null,
        pais: null,
        celular: null,
        comentario: null,
        terminos: true,
      },
      alert: false,
      // Belice, Costa Rica, El Salvador, Guatemala, Honduras, Nicaragua, Panamá;
      Paises: [
        { options: "Belice ", value: "Belice" },
        { options: "Costa Rica ", value: "Costa Rica" },
        { options: "El Salvador ", value: "El Salvador" },
        { options: "Guatemala ", value: "Guatemala" },
        { options: "Honduras ", value: "Honduras" },
        { options: "Nicaragua ", value: "Nicaragua" },
        { options: "Panamá ", value: "Panamá" },
      ],
    };
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
  // http://localhost:1337/contactos
  methods: {
    enviar: function (event) {
      event.preventDefault();

      Swal.fire({
        title:
          this.leng == "es"
            ? "¿Quieres guardar los cambios?"
            : "Do you want to save the changes?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: this.leng == "es" ? "Guardar" : "Save",
        denyButtonText: this.leng == "en" ? `Don't save` : `No guardar`,
        icon: "question",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (
            this.form.Nombre != null &&
            this.form.mail != null &&
            this.form.pais != null &&
            this.form.celular != null &&
            this.form.terminos != false
          ) {
            axios
              .post("http://localhost:1337/contactos", this.form)
              .then((response) => {
                console.debug(response);
                Swal.fire(
                  "Enviado!",
                  "Sus Datos han sidos enviados con exito",
                  "success"
                );
              })
              .catch((error) => {
                console.error(error);
              });

            this.form.Nombre = null;
            this.form.mail = null;
            this.form.pais = null;
            this.form.celular = null;
            this.form.comentario = null;
            this.form.terminos = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                this.leng == "es"
                  ? "Algunos datos no han sido llenados"
                  : "Some data has not been filled",
            });
          }
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
      console.debug(this.form);
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);

    // gsap.to('.phones', {x: 500, duration: 3})
  },
  created() {
    axios.get("https://jsonplaceholder.typicode.com/todos/1").then((result) => {
      console.log(result.data);
    });
  },
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
.cuadro {
  width: 30px;
  height: 5px;
  background-color: #c7893e;
  margin-bottom: 10px;
}

.Contact {
  height: 500px;
  width: 100%;
  background-image: url("../assets/trujillo-min.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: #c7893e 10px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: white;
  }
}
.img-bg {
  background-color: #c7893e;
  background-image: url("../assets/hondu.jpeg");
  background-size: cover;
  background-position: center;
  position: relative;
  @include responsive(mobile) {
    height: 600px;
    margin-bottom: -0px;
    box-shadow: none;
    z-index: 1;
  }
}
.wrap-grey {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
  display: flex;

  justify-content: space-between;
  align-items: center;
    @include responsive(mobile) {
    height: auto;
    margin-bottom: -0px;
    box-shadow: none;
    z-index: 1;
  }
}
.formulario {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;

  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrap-form {
    width: 70%;
      @include responsive(mobile) {
    
        width: 90%;
    
  }
  }
  @include responsive(mobile) {
    padding: 50px 30px 50px 30px;
  }
}
.conte {
  box-shadow: #bdbdbd2d 5px 10px 20px;
  @include responsive(mobile) {
    box-shadow: none;
  }
}
.swal2-icon.swal2-question {
  border-color: #c7893e;
  color: #c7893e;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #c7893e;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-cancel {
  background: initial;
  background-color: #181648;
  color: #fff;
  font-size: 1em;
}
.btn-size {
  width: 100% !important;
}
.wrap-info-contact {
  color: white;
  text-align: left;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.842);
  a {
    color: white;
    text-decoration: none;
    font-size: 20px;
      &:hover{
        color: #c7893e;
      }
  }
  position: absolute;
  bottom:50px;
  left:50px;
  .redes-socials{
    display: flex;
    a{
      margin-right: 20px;
      font-size: 30px;
      transition: 0.3s ease-in-out;
      &:hover{
        color: #c7893e;
      }
    }
  }
}
.close{
  border: none;
}
.modal-text{
  text-align: left !important;
}
</style>