<template>
<div class="container valores-wrap">
<div class="flip-card" v-for="item in (leng=='es')?Valores:Valores_en" :key="item.titulo">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <!-- <img src="img_avatar.png" alt="Avatar" style="width:300px;height:300px;"> -->
      <i :class="item.icon"></i>
      <h5>{{item.titulo}}</h5>
    </div>
    <div class="flip-card-back">
      <p>{{item.info}}</p>

    </div>
  </div>
</div>
</div>
</template>


<script>

import store from "@/store/index.js";
export default {
  name: "Valcard",

  components: {


  },
  data() {
    this.$i18n.locale = store.state.activo;
    return {
      leng: store.state.activo,
    Valores:[
      {titulo:'Integridad',icon:'fas fa-puzzle-piece',info:'El problema del cliente se convierte en nuestro y lo solucionamos dentro del marco legal y ético, sin transgredir nuestros valores.'},
      {titulo:'Honestidad',icon:'fas fa-hands-helping',info:'Actuamos en base a la ley, por lo que no prometemos resultados fuera de nuestro alcance o fuera de ella. Acostumbrados a reportar las horas trabajadas con exactitud para respaldar los honorarios pactados.'},
      {titulo:'Excelencia',icon:'fas fa-trophy',info:'Nos esforzamos por dar la mejor asesoría legal posible y cumplir con los tiempos de entrega. Somos conscientes que el derecho cambia y no se estanca, por eso estamos dispuestos a seguir aprendiendo y mejorando, a través del estudio y la experiencia.'},
      {titulo:'Responsabilidad',icon:'fad fa-handshake-alt',info:'Sabemos que los problemas legales de un cliente pueden significar la vida, por ello tomamos su problema como el nuestro, apuntando a la excelencia y comprometiéndonos a darle el seguimiento debido buscando el mejor resultado.'},
      {titulo:'Entrega y Servicio',icon:'fas fa-pen-alt',info:'Amamos nuestra profesión y vivimos para servirles a nuestros clientes, por eso damos el 100% en todo momento, dispuestos a atender consultas a cualquier hora y bajo cualquier circunstancia.'},
      {titulo:'Comunicación y Confianza',icon:'fas fa-comments',info:'Mantenemos una comunicación abierta con nuestros clientes porque es vital para una relación exitosa y longeva. Una buena comunicación transmite confianza obteniendo ambas partes mejores resultados.'},
    ],
    Valores_en:[
      {titulo:'Integrity',icon:'fas fa-puzzle-piece',info:"The client's problem becomes ours and we solve it within the legal and ethical framework, without transgressing our values."},
      {titulo:'Honesty',icon:'fas fa-hands-helping',info:'We act based on the law, so we do not promise results beyond the law and our moral reach. We report the hours worked accurately to support legal fees.'},
      {titulo:'Excellence',icon:'fas fa-trophy',info:'We strive to give the best possible legal advice and meet delivery times. We are aware that the law changes and does not stagnate, that is why we continue to learn and improve, by studying the new laws and law reforms made by our legislators.'},
      {titulo:'Responsibility',icon:'fad fa-handshake-alt',info:'We know that the legal problems of a client can mean the life to them, so we take their problem as ours, aiming for excellence and committing ourselves to achieve the best result possible.'},
      {titulo:'Delivery and Service',icon:'fas fa-pen-alt',info:'We love our profession and live to serve our clients, that is why we always give our 100%, by being ready to answer any inquiries at any time under any circumstance.'},
      {titulo:'Communication and Trustworthiness',icon:'fas fa-comments',info:'We maintain an open communication with our clients since we believe it is vital to a successful and long-lasting relationship. A solid communication, between both parties, transmits confidence and leads to better results.'},
    ]
    }
  },
   computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";
.valores-wrap{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;align-items: flex-start;
margin-bottom: 80px;
   @include responsive(mobile) {
flex-direction: column;
align-items: center;
    
      }
   @include responsive(small) {
flex-direction: row;
align-items: center;
    
      }
}
.flip-card {
  background-color: transparent;
  width: 350px;
height: 260px;
  // border: 1px solid #f1f1f1;
  margin-top: 30px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
     @include responsive(mobile) {
height: 350px !important;
width: 90%;
    
      }
     @include responsive(small) {
height: 350px !important;
width: 48%;
    
      }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  align-items: center;
  background-color: #f4f4f4;
  color: black;
  i{
    color: #c7893e;
    font-size: 3em;
    margin-bottom: 20px;
  }
}

/* Style the back side */
.flip-card-back {
  background-color: #c7893e;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotateY(180deg);
}
</style>
