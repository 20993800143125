<template>
<div class="container valores-wrap">
<div class="flip-card-val" v-for="item in datos" :key="item.titulo">
  <div class="flip-card-val-inner">
    <div class="flip-card-val-front">
      <i :class="item.icon"></i>
      <h6>{{item.titulo}}</h6>
    </div>
   
  </div>
</div>
</div>
</template>


<script>


export default {
  name: "Areas",
  props: {
    datos: Array,
   
  },
  components: {


  },
  data() {
    return {
    // datap: this.props.datos
    }
  },
  methods:{
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";
.valores-wrap{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;align-items: flex-start;
margin-bottom: 80px;
   @include responsive(mobile) {
flex-direction: column;
align-items: center;
    
      }
}
.flip-card-val {
  background-color: transparent;
  width: 300px;
height: 250px;
  // border: 1px solid #f1f1f1;
  margin-top: 30px;
  h6{
    width: 253px;
  }
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
     @include responsive(mobile) {
height: 350px !important;
width: 90%;
    
      }
}

/* This container is needed to position the front and back side */
.flip-card-val-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */


/* Position the front and back side */
.flip-card-val-front, .flip-card-val-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-val-front {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  align-items: center;
  background-color: #f4f4f4;
  color: black;
  i{
    color: #c7893e;
    font-size: 3em;
    margin-bottom: 20px;
  }
}

/* Style the back side */

</style>
