

<i18n>
{
  "en": {"Titulo": "Our Team",
  "sub":"We are a leading firm in Honduras formed by nine lawyers and notaries, with a strategic office in San Pedro Sula, which coordinates a team of legal advisors throughout the national territory with the ability to solve all your legal needs. We provide a nationwide service through a single point of contact for any of your legal transactions. ",
  "sub-2":"We are a firm that has all the advisory services in the different branches of law, focused on serving all types of companies and individuals who want to do business in Honduras. We are built by a team of lawyers and notaries, specialized in the different branches of law, to address any personal or business need. ",
   "contact":"Contact us",
   "mision":"Mission",
   "vision":"Vision",
   "context-mision":"We are a socially responsible legal firm based in Honduras, that offers the best legal services to the national and international community, demonstrating quality, diligence, efficiency and ethics in our work. ",
   "context-vision":"To be recognized nationally and internationally as the best law firm in Honduras, guaranteeing our clients integral solutions in their different needs through a high-quality service, adhering to the ethics of our work. "

 
  },

  "es": {"Titulo": "Nuestros Abogados",
    "sub":"Somos una firma líder en Honduras que cuenta con más de nueve abogados y una oficina estratégica en San Pedro Sula, que coordina un equipo de abogados a través del territorio nacional que resuelve todas tus necesidades legales. Les proveemos un servicio legal a empresas nacionales e internacionales a través de un solo punto de contacto para cualquiera de tus transacciones.",
    "sub-2":"Somos una firma líder en Honduras que cuenta con mas de 10 abogados y una oficina estratégica en San Pedro Sula, que coordina un equipo de abogados a través del territorio nacional que resuelve todas tus necesidades legales. Les proveemos un servicio a nivel nacional a través de un solo punto de contacto para cualquiera de tus transacciones.",
    "contact":"Contactanos",
  "mision":"Misión",
   "vision":"Visión",
   "context-mision":"Somos una firma hondureña socialmente responsable, ofreciendo nuestros mejores servicios legales a nuestra comunidad nacional e internacional, demostrando calidad, diligencia, eficiencia y ética en nuestro trabajo.",
   "context-vision":"Ser reconocidos a nivel nacional e internacional como la mejor firma en Honduras, garantizándole a nuestros clientes éxitos en sus necesidades legales a través de un servicio de la mas alta calidad, apegándonos a la ética en nuestro trabajo."

  }
}
</i18n>
<template>
  <div class="bg-team">
    <div class="desgradado"></div>
  </div>
<section class="Sobre">
  <div class="container wow animate__fadeIn"  data-wow-delay="1s" data-wow-duration="1s">

    <h1 class="texto-intro"> <b>{{$t("Titulo")}}</b> </h1>
    <div class="cuadro"></div>
    <p> {{ $t("sub")}}</p>
  </div>

</section>
<section class="Team">
  <div class="titulo-team wow animate__fadeIn"  data-wow-delay="1s" data-wow-duration="1s">

<h1  > <b>{{(leng=="es")?'Abogados': 'Lawyers'}}   </b> </h1>
<div class="cuadro"></div>
  </div>

<div class="container carrusel wow animate__fadeIn"  data-wow-delay="1s" data-wow-duration="1s">



<Carrusel   />



</div>

</section>

<!-- <section class="wow animate__fadeIn"  data-wow-delay="1s" data-wow-duration="1s">
  <div class="container values">
<h1> <b>{{(leng=="es")?'Nuestros Valores': 'Our Values'}} </b> </h1>
<div class="cuadro"></div>
<div class="valores">
  <ul>
    <li> {{(leng=="es")?'Integridad': 'Integrity'}}   </li>
 <li>   {{(leng=="es")?'Honestidad ': 'Honesty'}}</li>
 <li>  {{(leng=="es")?'Excelencia ': 'Excellence'}}</li>
 <li>  {{(leng=="es")?'Responsabilidad ': 'Responsibility'}}</li>
 <li>  {{(leng=="es")?'Respeto ': 'Respect'}}</li>
  </ul>
<ul>

 <li> {{(leng=="es")?'Resiliencia':'Resilience'}}</li>
 <li>  {{(leng=="es")?'Entrega y servicio':'Delivery and Service'}} </li>
 <li>  {{(leng=="es")?'Confianza o confiabilidad':'Confidence or trustworthiness'}} </li>
 <li> {{(leng=="es")?'Comunicación':'Communication'}}  </li>
 <li>  {{(leng=="es")?'Trabajo':'Work'}} </li>

  
  </ul>


</div>

  </div>
</section> -->
</template>

<script>
// @ is an alias to /src

import  Carrusel from '@/components/carrusel.vue'
import store from "@/store/index.js";
export default {
  name: "Team",
  components: {
    Carrusel
  }, data() {
    this.$i18n.locale = store.state.activo;
    return {
      leng: store.state.activo,
    };
  },
    computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
    mounted: function () {
    window.scrollTo(0,0);
    
    // gsap.to('.phones', {x: 500, duration: 3})
  }
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
.linea {
  width: 50px;
  height: 10px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.carru{
  width: 400px;
  height: auto;
  background-color: #c7893e;
}
.cuadro{
  width: 30px;
  height: 5px;
   background-color: #c7893e;
   margin-bottom: 10px;
}
.bg-team {
  height: 70vh;
  width: 100%;
  background-image: url("../assets/noche-min.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: #c7893e 10px solid;
  display: flex;
  align-items: center;
  justify-content: center;  
   @include responsive(mobile) {
    height: 300px;
    
      }
  h1{
    color: white;
  }
}
.Sobre {
  width: 100%;
  height: 30vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
 @include responsive(mobile) {
       height: auto;
       padding: 20px;
    
       text-align: justify;
       
    
      }
  justify-content: space-between;
  align-items: center;

  .container {
  display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
    // color: #181648;
 
    .texto-intro {
      text-align: justify;
    }
  }
}
.somos{
  margin-top: 50px;
   @include responsive(mobile) {
      padding: 20px;
      text-align: justify;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    
      }
  img{
    padding: 20px;
  }
}
.Cuestions {
  width: 100%;
  height: auto;

  .count {
    background-color: #181648;
    height: 150px;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .count_num {
      width: 100px;
      height: 100px;
      color: white;
      font-family: "Quicksand", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
.mision_vision{
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  align-items: center;
  margin-top: 50px;
     @include responsive(mobile) {
       flex-direction: column;
     }
  .cart-d{
    width: 49%;
    height: 300px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    padding: 50px;

    text-align: justify;
       @include responsive(mobile) {
   width: 90%;
   height: auto;
   margin-bottom: 20px;
     }
  }
}
.img {
  background-image: url("../assets/catedral-sps.jpg");

  background-size: cover;
  background-position: center;
}
.cuestions-Text {
  text-align: justify;
  padding-left: 30px;
}
.values {
  text-align: center;

  margin-top: 50px;
  .row {
    .wrap-value {
      padding: 20px;
      text-align: justify;
    }
  }
}
.Team{
  height: auto;
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
    margin-bottom: 100px;
  .titulo-team{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.carrusel{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
     @include responsive(mobile) {
   justify-content: center; 
    
      }
  
}
.values{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
.valores{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 100px;
     @include responsive(mobile) {
  flex-direction: column;
    
      }
  ul{
    width: 49% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
         @include responsive(mobile) {
    width: 95% !important;
    
      }

    li{
      text-align: justify;
      font-size: 20px;
      list-style: none;
      line-height: 50px;
      transition: 0.3s ease-in-out;
          background-color: #f4f4f4;
          margin-bottom: 20px;
      // border-bottom: 2px #c7893e solid;
      width: 100%;
      text-align: center;
     
    }
  }
}


}

</style>