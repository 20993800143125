<template>
  <div class="about">
    <div class="desgradado"></div>
      <h1 class="texto-intro" style="text-shadow:2px 2px 20px #000;"><b>  {{leng=='es'?'Áreas de Práctica':'Practice Areas'}}</b></h1>
  </div>

  <section clas="">
    <div class="container items-services">
      <div class="row">
        
   <Areas 
   :datos="leng=='es'?Areas:Areas_en"
   />
        
       
      </div>
    </div>
  </section>

</template>


<script>
// @ is an alias to /src
import store from "@/store/index.js";
import Areas  from "@/components/Areas.vue"

export default {
  name: "services",
  components: {
    Areas
  },
  data(){
  this.$i18n.locale = store.state.activo;
  return{
    leng: store.state.activo,
    Areas:[
      {titulo:'Derecho Corporativo / Mercantil',icon:'far fa-handshake'},
      {titulo:'Derecho Penal',icon:'far fas fa-gavel'},
      {titulo:'Derecho Bancario y Financiero',icon:'fas fa-piggy-bank'},
      {titulo:'Propiedad Intelectual',icon:'far fa-lightbulb'},
      {titulo:'Protección de Datos & Protección al Consumidor',icon:'fas fa-database'},
      {titulo:'Derecho Tributario / Fiscal',icon:'fas fa-file-invoice-dollar'},
      {titulo:'Derecho Inmobiliario, Bienes & Raíces y Hotelería',icon:'fas fa-hotel'},
      {titulo:'Planificación Patrimonial y Sucesoria ',icon:'fas fa-comments-dollar'},
      {titulo:'Derecho Laboral',icon:'fas fa-user-tie'},
      {titulo:'Litigios Civiles',icon:'fas fa-balance-scale'},
      {titulo:'Arbitrajes',icon:'fas fa-people-arrows'},
      {titulo:'Derecho Público y Contencioso Administrativo ',icon:'fas fa-gavel'},
      {titulo:'Contratación del Estado y Alianza Público Privada ',icon:'fas fa-file-signature'},
      {titulo:'Servicios Notariales',icon:'fas fa-file-alt'},
      {titulo:'Derecho Ambiental y de Salud',icon:'fas fa-tree'},
      {titulo:'Licencias Ambientales y Registros Sanitarios de Productos',icon:'fas fa-leaf'},
      {titulo:'Regulaciones y Permisos',icon:'far fa-check-circle'},
    ],
    Areas_en:[
      {titulo:'Corporate and Business Law',icon:'far fa-handshake'},
            {titulo:'Criminal Law',icon:'far fas fa-gavel'},
      {titulo:'Banking & Finance Law ',icon:'fas fa-piggy-bank'},
      {titulo:'Intellectual Property',icon:'far fa-lightbulb'},
      {titulo:'Data Protection and Consumer Protection',icon:'fas fa-database'},
      {titulo:'Tax Law',icon:'fas fa-file-invoice-dollar'},
      {titulo:'Real Estate Law & Hospitality',icon:'fas fa-hotel'},
      {titulo:'Estate Planning and Succession',icon:'fas fa-comments-dollar'},
      {titulo:'Labor Law',icon:'fas fa-user-tie'},
      {titulo:'Litigation',icon:'fas fa-balance-scale'},
      {titulo:'Arbitration',icon:'fas fa-people-arrows'},
      {titulo:'Public Law & Public Procurement',icon:'fas fa-gavel'},
      {titulo:'State Contracts & Public Private Alliances',icon:'fas fa-file-signature'},
      {titulo:'Notary Services',icon:'fas fa-file-alt'},
      {titulo:'Environmental and Health Law',icon:'fas fa-tree'},
      {titulo:'Sanitary & Environmental Licenses & Registries',icon:'fas fa-leaf'},
      {titulo:'Regulations and Permits',icon:'far fa-check-circle'},
    ],
        }
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
    mounted: function () {
    window.scrollTo(0,0);
    
    // gsap.to('.phones', {x: 500, duration: 3})
  }
};
</script>


<style lang="scss" scoped>
.cuadro {
  width: 30px;
  height: 5px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.items-services {
  text-align: justify;
  margin-top: 100px;
  margin-bottom: 50px;
}
.about {
  height: 500px;
  width: 100%;
  background-image: url("../assets/spsmin.jpg");
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: #c7893e 10px solid;
  display: flex;
  background-color: #181648;
  align-items: center;
  justify-content: center;
  h1 {
    color: white;
  }
}
.grey{
  background-color: #f4f4f4;
  padding-top: 50px;
}
.Sobre {
  width: 100%;
  height: 300px;
  background-color: #f4f4f4;
  display: flex;

  justify-content: space-between;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #181648;

    .texto-intro {
      text-align: justify;
   
    }
  }
}
li {
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: #181648;
    color: #c7893e;
    border-radius: 5px;
    padding-left: 10px;
  }
}

</style>
