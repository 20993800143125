<template>
  <div class="Sectoress">
    <div class="desgradado"></div>
      <h1 class="texto-intro" style="text-shadow:2px 2px 20px #000;"><b> {{leng=="es"?"Sectores":"Sectors"}}   </b></h1>
  </div>
  <!-- <section class="Sobre">
    <div class="container">
      <div class="cuadro"></div>
      
    </div>
  </section> -->
  <section clas="">
    <div class="container items-services">
      <div class="row">
        
   <Areas 
   :datos="leng=='es'?Sectores:Sectores_en"
   />
       
       
      </div>
    </div>
  </section>

</template>


<script>
// @ is an alias to /src
import store from "@/store/index.js";
import Areas  from "@/components/Areas.vue"

export default {
  name: "Sectores",
  components: {
    Areas
  },
  data(){
    this.$i18n.locale = store.state.activo;
  return{
    leng: store.state.activo,
    Sectores:[
      {titulo:'Agroindustria',icon:'fas fa-tractor'},
      {titulo:'Construcción e Infraestructura',icon:'fas fa-hard-hat'},
      {titulo:'Aviación, Marítimo y Transporte Terrestre',icon:'fas fa-plane-departure'},
      {titulo:'Capital Privado y Capital de Riesgo',icon:'fad fa-funnel-dollar'},
      {titulo:'Comercio Internacional',icon:'fas fa-globe-americas'},
      {titulo:'Impuestos / IVA',icon:'fas fa-percentage'},
      {titulo:'Cumplimiento Regulatorio, Prevención y Gestión de Riesgos',icon:'fas fa-users-cog'},
      {titulo:'Aseguradoras y Seguros',icon:'fas fa-shield-alt'},
      {titulo:'Pequeña y Mediana Empresa',icon:'fas fa-store'},
      {titulo:'Zonas Libres y Zonas Francas',icon:'fas fa-city'},
      {titulo:'Migración Nacional y Global ',icon:'fas fa-walking'},
      {titulo:'Sectores Regulados (Energía, Energía Renovable y Minería)',icon:'fas fa-fan'},
      {titulo:'Maquila e Industria Textil ',icon:'fas fa-tshirt'},
    ],
    Sectores_en:[
      {titulo:'Agro-business',icon:'fas fa-tractor'},
      {titulo:'Construction and Infrastructure',icon:'fas fa-hard-hat'},
      {titulo:'Aviation, Marine and Terrestrial Transportation',icon:'fas fa-plane-departure'},
      {titulo:'Private and Venture Capital',icon:'fad fa-funnel-dollar'},
      {titulo:'International Trade',icon:'fas fa-globe-americas'},
      {titulo:'Taxes / IVA',icon:'fas fa-percentage'},
      {titulo:'Regulatory Compliance, Prevention and Risk Management',icon:'fas fa-users-cog'},
      {titulo:'Insurance and Reinsurance',icon:'fas fa-shield-alt'},
      {titulo:'Small and Medium Businesses',icon:'fas fa-store'},
      {titulo:'Free Trade Zones',icon:'fas fa-city'},
      {titulo:'Global and National Migration',icon:'fas fa-walking'},
      {titulo:'Regulated Sectors (Energy, Free Trade Zones, Mining, Industry)',icon:'fas fa-fan'},
      {titulo:'Textile Industry',icon:'fas fa-tshirt'},
    ],
        }
  }
  ,
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
};
</script>


<style lang="scss" scoped>
.cuadro {
  width: 30px;
  height: 5px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.items-services {
  text-align: justify;
  margin-top: 100px;
  margin-bottom: 50px;
}
.Sectoress {
  height: 500px;
  width: 100%;
  background-image: url("../assets/montana-min.jpg");
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: #c7893e 10px solid;
  display: flex;
  background-color: #181648;
  align-items: center;
  justify-content: center;
  h1 {
    color: white;
  }
}
.grey{
  background-color: #f4f4f4;
  padding-top: 50px;
}
.Sobre {
  width: 100%;
  height: 300px;
  background-color: #f4f4f4;
  display: flex;

  justify-content: space-between;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #181648;

    .texto-intro {
      text-align: justify;
   
    }
  }
}
li {
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: #181648;
    color: #c7893e;
    border-radius: 5px;
    padding-left: 10px;
  }
}

</style>
