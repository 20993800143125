

<i18n>
{
  "en": {"Titulo": "About us",
  "sub":"We are a leading firm in Honduras formed by nine lawyers and notaries, with a strategic office in San Pedro Sula, which coordinates a team of legal advisors throughout the national territory with the ability to solve all your legal needs. We provide a nationwide service through a single point of contact for any of your legal transactions.",
  "sub_2":"We are a firm that has all the advisory services in the different branches of law, focused on serving all types of companies and individuals who want to do business in Honduras. We are built by a team of lawyers and notaries, specialized in the different branches of law, to address any personal or business need. ",
   "contact":"Contact us",
   "mision":"Mission",
   "vision":"Vision",
   "context-mision":"We are a socially responsible legal firm based in Honduras, that offers the best legal services to the national and international community, demonstrating quality, diligence, efficiency, and ethics in our work. ",
   "context-vision":"To be recognized nationally and internationally as the best law firm in Honduras, guaranteeing our clients integral solutions in their different needs through a high-quality service, adhering to the ethics of our work."

 
  },

  "es": {"Titulo": "Sobre nosotros",
    "sub":"Somos una firma líder en Honduras que cuenta con nueve abogados y notarios, con una oficina estratégica en San Pedro Sula, que coordina un equipo de asesores legales a través del territorio nacional que resuelven todas tus necesidades legales. Te proveemos un servicio a nivel nacional a través de un solo punto de contacto para cualquiera de tus transacciones legales.",
    "sub_2":"Somos una firma que cuenta con todos los servicios de asesoría en las diferentes ramas del derecho, enfocada en servirle a todo tipo de empresas y personas naturales que quieran hacer negocios en Honduras. Contamos con abogados y notarios, especializados en las diferentes ramas del derecho, para abordar cualquier necesidad personal o de tu empresa.",
    "contact":"Contactanos",
  "mision":"Misión",
   "vision":"Visión",
   "context-mision":"Somos una firma legal orgullosamente hondureña y socialmente responsable, ofreciendo los mejores servicios legales a nuestra comunidad nacional e internacional, demostrando calidad, diligencia, eficiencia y ética en nuestro trabajo.",
   "context-vision":"Ser reconocidos a nivel nacional e internacional como la mejor firma de abogados en Honduras, garantizándole a nuestros clientes soluciones integrales en sus distintas necesidades a través de un servicio de alta calidad, apegada a la ética de nuestro trabajo."

  }
}
</i18n>
<template>
  <div class="about" >
    <div class="desgradado"></div>
  </div>
  <section class="Sobre" id="nosotros">
    <div class="container">
      <div
        class="wow animate__fadeIn container row"
        data-wow-delay="1s"
        data-wow-duration="1s"
      >
        <div class="col-sm-12 col-lg-3 col">
          <h1 class="texto-intro">
            <b>{{ $t("Titulo") }}</b>

          </h1>
          <div class="cuadro"></div>
          <h4 style="text-transform:none"> {{leng=="es"?'Tus socios legales para los negocios en Honduras.':'Your legal partners for business in Honduras.'}} </h4>
        </div>
        <div class="col-sm-12 col-lg-9 col">
          <p>{{ $t("sub") }}</p>
          <hr />
          <p>{{ $t("sub_2") }}</p>
        </div>
      </div>
    </div>
  </section>
  <div
    class="container somos wow animate__fadeIn"
    data-wow-delay="1s"
    data-wow-duration="1s"
  >
    <img src="@/assets/award.svg" alt="" />
  </div>
  <section class="mision_vision container">
    <div
      class="cart-d wow animate__fadeIn"
      data-wow-delay="1s"
      data-wow-duration="1s"
    >
      <h3>
        <b>{{ $t("mision") }}</b>
      </h3>
      <div class="cuadro"></div>
      <p>{{ $t("context-mision") }}</p>
    </div>
    <div
      class="cart-d wow animate__fadeIn"
      data-wow-delay="1s"
      data-wow-duration="1s"
    >
      <h3>
        <b>{{ $t("vision") }}</b>
      </h3>
      <div class="cuadro"></div>
      <p>{{ $t("context-vision") }}</p>
    </div>
  </section>
  <section
    class="wow animate__fadeIn"
    data-wow-delay="1s"
    data-wow-duration="1s"
    id="Valores"
  >
    <div class="container values">
      <h1>
        <b>{{ leng == "es" ? "Nuestros Valores" : "Our Values" }} </b>
      </h1>
      <div class="cuadro"></div>

<Valcard/>

    </div>
  </section>
</template>

<script>

import Valcard from "@/components/CardValores.vue"
import store from "@/store/index.js";
export default {
  name: "About",
  components: {

    Valcard
  },
  data() {
    this.$i18n.locale = store.state.activo;
    return {
      leng: store.state.activo,
    };
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);

    // gsap.to('.phones', {x: 500, duration: 3})
  },
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
.linea {
  width: 50px;
  height: 10px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.carru {
  width: 400px;
  height: auto;
  background-color: #c7893e;
}
.cuadro {
  width: 30px;
  height: 5px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.about {
  height: 70vh;
  width: 100%;
  background-image: url("../assets/home-min.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: #c7893e 10px solid;
  background-color: #c7893e;
  display: flex;
  align-items: center;
  justify-content: center;
  @include responsive(mobile) {
    height: 300px;
  }
  h1 {
    color: white;
  }
}
.Sobre {
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive(mobile) {
    height: auto;
    padding-top: 50px !important;

    text-align: left;
  }
  justify-content: space-between;
  align-items: center;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #181648;

    @include responsive(mobile) {
      flex-direction: column;
    }
    .col {
      text-align: left;
    }
  }
}
.somos {
  margin-top: 10px;
  @include responsive(mobile) {
    padding: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  img {
    padding: 20px;
  }
}
.Cuestions {
  width: 100%;
  height: auto;

  .count {
    background-color: #181648;
    height: 150px;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .count_num {
      width: 100px;
      height: 100px;
      color: white;
      font-family: "Quicksand", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
.mision_vision {
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
  @include responsive(mobile) {
    flex-direction: column;
  }
  @include responsive(small) {
    flex-direction: column;
  }
  .cart-d {
    width: 49%;
    min-height: 300px;
    max-height: auto;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    padding: 50px;

    text-align: left;
    @include responsive(mobile) {
      width: 90%;
      min-height: auto;
      margin-bottom: 20px;
    }
    @include responsive(small) {
      width: 90%;
      min-height: auto;
      margin-bottom: 20px;
    }
  }
}
.img {
  background-image: url("../assets/catedral-sps.jpg");

  background-size: cover;
  background-position: center;
}
.cuestions-Text {
  text-align: left;
  padding-left: 30px;
}
.values {
  text-align: center;

  margin-top: 50px;
  .row {
    .wrap-value {
      padding: 20px;
      text-align: left;
    }
  }
}
.Team {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
  margin-bottom: 100px;
  .titulo-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.carrusel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include responsive(mobile) {
    justify-content: center;
  }
}
.values {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .valores {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 100px;
    @include responsive(mobile) {
      flex-direction: column;
    }
    ul {
      width: 49% !important;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include responsive(mobile) {
        width: 95% !important;
      }

      li {
        text-align: left;
        font-size: 20px;
        list-style: none;
        line-height: 50px;
        transition: 0.3s ease-in-out;
        background-color: #f4f4f4;
        margin-bottom: 20px;
        // border-bottom: 2px #c7893e solid;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>